.verdeapp-wrapper {
  .hero__section {
    background: linear-gradient(90deg, #02d9a7 0%, #02d17f 100%);
  }
  .verdeapp-heading {
    h3 {
      font-weight: 700;
    }
    h4 {
      font-weight: 300;
      max-width: 800px;
      line-height: 1.3;
      color: rgba(0, 0, 0, 0.7);
      font-size: 1.6rem;
      margin-top: 1rem;
    }
  }
}
.qr__codescanner {
  background: #f6f6f6;
  overflow: hidden;
  position: relative;
  .qrHeadingWrappers {
    position: absolute;
    width: 25%;
    top: 0%;
    left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  div {
    height: 700px;
    margin: 0 auto;
    width: 320px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
    .phonemockup {
      z-index: 10;
    }
    .scanScreen {
      padding: 30px;
      z-index: 8;
    }
    .barcode {
      right: 0;
      margin: auto;
      width: 160px;
    }
    .scanline {
      margin: auto;
      width: 180px;
      position: absolute;
      top: 37%;
      right: 0;
      z-index: 9;
      left: 0;
    }
    .chargingbolt {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background-color: #f6f6f6;
      z-index: 9;
      position: relative;
      span {
        position: relative;
      }
      .msg {
        padding-bottom: 10px;
        margin-top: 2rem;
      }
      .svg-green {
        fill: #03d078;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 80%;
        width: 100%;
        background-image: url("../assets/verdeAppAssets/qrscan/chargingbolt.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-attachment: fixed;
        background-position: bottom left;
      }
    }
  }
}

@media (max-width: 1200px)
{
  .qr__codescanner {
    div {
      margin-right: 100px;
    }
    .verdeapp-heading {
      width: 35%;
    }
    .chargingbolt{
      left: 50px;
    }
  }
}

@media (max-width: 992px)
{
  .qr__codescanner {
    div {
      margin-right: 20px;
    }
    .chargingbolt {
      left: 0px;
    }
  }
}

@media (max-width: 786px) {
  .qr__codescanner {
    background: #f6f6f6;
    overflow: hidden;
    div {
      height: 500px;
      margin: 0 auto;
      width: 260px;
      margin-right: 20px;
      .barcode {
        right: 0;
        margin: auto;
        width: 120px;
      }
    }
    .chargingbolt {
      left: 0px;
    }
  }
}

@media (max-width: 586px)
{
  .qr__codescanner {
    div {
      margin-right: 0px;
    }
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
