.website__glimpse {
    width: 80%;
    margin: 0 auto;
    display: flex;
}

.website__glimpse__reverse {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
}

.website__glimpse__left {
    width: 40%;

    h2 {
        font-size: 4.5rem;
    }

    p {
        color: #fff;
        padding-left: 2rem;
        position: relative;
        margin-top: 3%;
    }
}

.website__glimpse__right {
    width: 60%;
    padding-top: 5%;
}

.website__glimpse__arrow {
    position: absolute;
    width: 60%;
    top: 90%;
    right: -15%;
}

.website__glimpse__arrow__reverse {
    position: absolute;
    width: 60%;
    top: 80%;
    left: -15%;
}




@media screen and (max-width: 800px) {

    .website__glimpse {
        flex-direction: column;
    }

    .website__glimpse__reverse {
        flex-direction: column;
    }

    .website__glimpse__left {
        width: 100%;
    }

    .website__glimpse__right {
        width: 100%;
    }

    .website__glimpse__arrow {
        display: none;
    }

    .website__glimpse__arrow__reverse {
        display: none;
    }
}