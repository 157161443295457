.pagerheading__atom__reverse{
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  position: relative;
  h4{
    padding-left: 5px;
  }
  h3{
    padding-right: 5px;
  }
}

.pagerheading__atom{
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  h4{
    padding-right: 5px;
  }
  h3{
    padding-left: 5px;
  }
}

.pager__line{
  position: absolute;
  top: 50%;
  height: 3px;
  width: 100%;
  z-index: 0;
  background-color: black;
}

@media (max-width: 995px)
{
  .pagerheading__atom {
    h3 {
      font-size: 2.5rem
    }
  }
}

@media (max-width: 795px)
{
  .pagerheading__atom {
    h3 {
      font-size: 2.2rem;
    }
    h4 {
      font-size: 1.8rem;
    }
  }
}

@media (max-width: 700px) {
 .pager__line {
  display: none;
 }

 .pagerheading__atom {
  justify-content: flex-start;
  h3 {
    padding-left: 8px;
    line-height: 2.5rem;
  }
  h4 {
    line-height: 1.3rem;
  }

 }
}

