.vch__logo{
    width: 60%;
    margin: 10rem auto;
    img{
        width: 100%;
        object-fit: contain;
    }
}

.creame__background{
    background-color: rgba($color:#FF8F00, $alpha: 0.1);
}