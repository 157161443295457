.evreBrandingWrapper {
  background-color: black;
}
.max-w-sm {
  max-width: 700px;
}
.max-w-lg {
  max-width: 900px;
}

.grid-layout {
  display: flex;
  justify-content: flex-end;
}

.evre__branding__vertical__carousel__wrapper {
  background-color: black;
  display: flex;
  gap: 5%;
  .heading {
    max-width: 480px;
    margin-left: 10%;
    h2 {
      color: white;
      font-family: Access__regular;
      font-weight: 300;
    }
  }
  .vertical__carousel {
    height: fit-content;
    height: 100vh;
    overflow: hidden;
    .slides {
      animation: verticalSlider 2s 2s infinite alternate;
    }
    .slide {
      height: 100vh;
    }
  }
}

@keyframes verticalSlider {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-60%);
    animation-play-state: paused;
  }
}
