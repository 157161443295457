// --verde-primary: #4FD399;
// --verde-secondary:#16202A;
.verde-bgp {
  background-color: #4fd399;
}
.verde-bgs {
  background-color: #16202a;
}
.verde-bgpl {
  background-color: #05dbb5;
}
.verde-hero {
  padding: 10% 0;
  border: none;
  h4 {
    max-width: 600px;
    color: white;
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
  }
}
.verde-design-wrapper {
  width: 80%;
  margin: 0 auto;
  .verde-bgs {
    padding: 10% 5%;
  }
  .verde-shapes {
    padding: 5% 0;
    svg {
      stroke: white;
      font-size: 2.5rem;
      padding-top: 4px;
    }
    h4 {
      padding: 0 1rem;
      padding-right: 3rem;

      font-weight: 300;
    }
  }
  h3 {
    max-width: 600px;
    color: white;
    font-weight: 200;
    text-align: justify;
    line-height: 1.5;
  }
}

.infinite-carousel {
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  &:hover {
    background: #16202a;
    .slide {
      color: transparent;
      -webkit-text-stroke: #05dbb5;
      -webkit-text-stroke-width: 1px;
      font-weight: bold;
    }
    svg {
      transform: rotate(120deg);
    }
  }
  .slider {
    overflow: hidden;
    padding: 10px;
    color: #000;
    svg {
      font-size: 2.4rem;
      stroke: #05dbb5;
      margin: 0 1rem;
      transition: all 0.4s ease;
    }
    .slide-track {
      animation: scroll 24s linear infinite;
      display: flex;
    }
    .slide {
      white-space: nowrap;

      font-weight: 300;
      display: flex;
      transition: all 0.3s ease;
    }
  }
}

// verde verde__carousel__flicking

.verde__slider {
  background-color: #141414;
}

.verde__carousel__flicking {
  background-color: #141415;
  .slides__flicking {
    padding-bottom: 80px;
    .slide {
      padding-left: 80px;
      z-index: 99 !important;
      height: fit-content;
      position: relative;
      .slide__bg {
        position: absolute;
        padding-left: 80px;
        height: 100%;
        width: 100%;
        bottom: -60px;
        left: -60px;
        z-index: -1 !important;
      }
    }
  }

  .flicking-arrow-prev {
    left: 20%;
  }
  .flicking-arrow-prev {
    border: 2px solid white;
    border-radius: 50%;
    &::after {
      background-color: white;
    }
    &::before {
      background-color: white;
    }
  }
  .flicking-arrow-next {
    right: 20%;
  }
  .flicking-arrow-next {
    border: 2px solid white;
    border-radius: 50%;
    &::after {
      background-color: white;
    }
    &::before {
      background-color: white;
    }
  }
}

@media (max-width: 786px) {
  .verde__slider {
    display: none;
  }
  .verde-design-wrapper {
    width: 85%;
  }
  .verde-shapes {
    padding: 10% 0 !important;
    gap: 1rem;
    flex-direction: column;
  }
}

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes slider {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-50%);
  }
}
