.varaha-container .sec-gradient {
  background: linear-gradient(127.88deg, #004137 3.68%, #006167 101.06%),
    #d9d9d9;
}
.varaha-container .primary-gradient {
  background: linear-gradient(127.88deg, #001a18 3.68%, #063436 101.06%),
    #d9d9d9;
}
.varaha-container .primary {
  background: #ccdbbc;
}
.varaha-container .primary-dark {
  background: #001a18;
}
.varaha-container .primary-light {
  background: #deebd7;
}
.varaha-container .secondary {
  background: rgba(255, 240, 222, 1);
}
.varaha-container .dashed-line {
  border-top: 3px solid grey;
  border-style: dashed;
  max-width: 500px;
}
.varaha-container .dashed-line {
  border-top: 2px solid grey;
  border-style: dotted;
  max-width: 500px;
}
.varaha-container .flexwrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .flex {
    display: flex;
    gap: 2rem;
  }
  .identity {
    padding: 10% 2%;
    text-align: center;
    h3 {
      color: #ddebd7;
      font-weight: 300;
    }
  }
}

.mission-wrapper {
  background: #d8e0a5;
  background-image: url("../assets/varahaAssets/pattern-green.png");
  background-repeat: no-repeat;
  background-position: top 40% center;
  height: 100vh;
  position: relative;

  h3 {
    position: absolute;
    top: 15%;
    left: 20%;
    color: #004345;
  }
  h4 {
    position: absolute;
    bottom: 20%;
    right: 10%;
    font-size: 2.4rem;
    font-weight: 300;
    max-width: 600px;
    padding: 0 2rem;
    line-height: 1.4;
  }
}

.varaha-description {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  p {
    line-height: 1.2;
    color: white;
    max-width: 400px;
  }
  h4 {
    max-width: 900px;
    color: #ccdbbc;
    font-weight: 400;
    text-transform: uppercase;
    text-align: left;
    margin: 0 auto;
    line-height: 1.4;
    padding: 0 2rem;
  }
  .mission-vision {
    display: flex;
    gap: 2rem;
    .mission {
      padding: 3rem;
      flex: 1 1 400px;
      background: #001a18;
    }
    .vision {
      padding: 3rem;
      flex: 1 1 400px;
      background: #001a18;
    }
    img {
      padding: 1rem;
    }
  }
}

.interactive-wrapper {
  background: url("../../src/assets/varahaAssets/bg-pattern.png"),
    linear-gradient(127.88deg, #001a18 3.68%, #063436 101.06%);
  background-position: top right;
  background-repeat: no-repeat;
  color: white;
  height: calc(100vh - 460px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    margin-bottom: 1rem;
  }
  h5 {
    font-weight: 200;
    color: #d9d9d9;
    font-size: 1.5rem;
    margin-top: 1rem;
    max-width: 600px;
    line-height: 1.5;
  }
}

.page__view {
  .about-us {
    position: relative;
    &::after {
      content: "ABOUT US";
      position: absolute;
      top: 0;
      left: 0;
      border: 3px solid #8fffe9;
      border-radius: 25px;
      transform: translate(-110px, 54px) rotate(-90deg);
      padding: 8px 18px 10px 18px;
      color: #8fffe9;

      font-size: 1.5rem;
    }
  }
  .business {
    position: relative;
    &::after {
      content: "BUSINESS";
      position: absolute;
      top: 0;
      right: 0;
      border: 3px solid #8fffe9;
      border-radius: 25px;
      transform: translate(110px, 54px) rotate(-90deg);
      padding: 8px 18px 10px 18px;
      color: #8fffe9;

      font-size: 1.5rem;
    }
  }
}

@media (max-width:900px){
  .page__view .business{
    &::after {
      display: none;
    }
  }
}

.varaha-container .flex-wrapper {
  height: 80vh;
  width: 100%;
  display: grid;
  place-content: center;
  min-height: 250px;
}
.varaha-container .varaha-typography-wrapper {
  h3 {
    font-weight: 600;
    line-height: 1.2;
    color: #004345;
  }
  h4 {
    font-size: 1.6rem;
    font-weight: 300;
    color: #004345;
    margin: 2% 0;
    max-width: 600px;
    line-height: 1.4;
  }

  hr {
    width: 100%;
    height: 2px;
    border: 1px solid #004345;
  }

  .fonts-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 1rem;
    div:last-child {
      padding-left: 2rem;
      border-left: 2px solid #004345;
      padding-bottom: 4rem;
    }
  }
}

.varaha__tnx {
  div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 40%;
    margin: 0 auto;
  }
  h4 {
    color: #ddebd7;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.4;
    margin-top: 1rem;
    text-align: center;
  }
  .image {
    max-width: 320px;
  }
  .title {
    max-width: 320px;
    margin-top: 2rem;
  }
  .chakra {
    max-width: 150px;
    margin-top: 2rem;
  }
}

// utilities
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-sm {
  display: flex;
  gap: 2rem;
}
@media (max-width: 786px) {
  .varaha-container .flex-wrapper {
    height: fit-content;
    width: 100%;
    display: grid;
    place-content: center;
  }

  .varaha-container .product-logo-wrapper {
    .image {
      max-width: 200px;
    }
  }
  .flex-sm {
    flex-direction: column;
    gap: 2rem;
  }
  .varaha-description {
    .mission-vision {
      flex-direction: column;
    }
  }
  .mission-wrapper {
    background-size: 80%;
    height: 50vh;
    h3 {
      left: 5% !important;
    }
  }
  .interactive-wrapper {
    height: 40vh;
    background: none, linear-gradient(127.88deg, #001a18 3.68%, #063436 101.06%);
  }
  .varaha-container .flexwrap {
    .identity {
      h3 {
        font-size: 2rem !important;
        font-weight: 300;
        padding: 0 1rem;
      }
    }
  }
}
