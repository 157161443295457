.sleek__history {
  width: 80%;
  margin: 15rem auto;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.golden__background {
  background-color: #d9af87;
}

.grey__background {
  background-color: #383838;
}

.bottom__padding {
  padding-bottom: 10rem;
}

.top__padding {
  padding-top: 10rem;
}
