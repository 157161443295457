@font-face {
  font-family: 'Epilogue';
  src: url(../assets/fonts/Epilogue-Bold.ttf);
  font-weight: 'normal';
}

@font-face {
  font-family: 'Epilogue-light';
  src: url(../assets/fonts/Epilogue-Light.ttf);
  font-weight: 'normal';
}

@font-face {
  font-family: 'Epilogue-Medium';
  src: url(../assets/fonts/Epilogue-Medium.ttf);
  font-weight: 'normal';
}
@font-face {
  font-family: 'Epilogue-regural';
  src: url(../assets/fonts/Epilogue-Regular.ttf);
  font-weight: 'normal';
}
.font_epilogue {
  font-family: 'Epilogue';
}
.font_epilogue_regural {
  font-family: 'Epilogue-regural';
}

.saving_container {
  background-image: linear-gradient(
    to bottom,
    rgba(26, 26, 27, 0),
    rgb(14, 11, 11)
  );
  background-color: #000000;
}
.saving {
  color: #e75553;
  font-size: 8rem;
  margin-left: 10%;
  font-style: normal;
  width: 25%;
  padding: 12% 0;
}
.main_design {
  background-color: #000000;
  overflow: hidden;
}
.epilogue h2:hover {
  transform: scale(1.2);
}
.ul_rudo {
  ul li {
    text-align: center;
    min-width: 30%;
    margin: auto;
    // border: 2px solid #e75553;
  }
  li:nth-child(2) {
    border-right: 2px solid #e75553;
    border-left: 2px solid #e75553;
  }
  // li:nth-child(1) {
  //   border-right: 2px solid #e75553;
  //   border-top: 2px solid #e75553;
  //   border-bottom: 2px solid #e75553;
  // }
  // li:nth-child(3) {
  //   border-right: 2px solid #e75553;
  //   border-top: 2px solid #e75553;
  //   border-bottom: 2px solid #e75553;
  // }
  ul {
    display: flex;
    width: 40%;
    margin: 10% auto;
    list-style: none;
    font-family: 'Epilogue-regural';
    color: #e75553;
    border: 2px solid #e75553;
  }
}
.logo_img {
  width: 17%;
  margin: 7% auto;
  display: block;
}
.logo_two_img {
  width: 45%;
  margin: 7% auto;
  display: block;
}
.concept_container {
  background-color: aliceblue;
  padding: 10% 0;
}
.logo_design ul li:nth-child(2) {
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.logo_design ul {
  border: 2px solid black;
  font-family: 'Epilogue-regural';
  margin: 0 auto;
  color: #000000;
}
.animation {
  display: flex;
  margin: auto auto;
  width: 80%;
  justify-content: center;
}
.rudo_design {
  align-items: center;
  justify-content: center;
  padding: auto auto;
  width: 50%;
  animation: changes 5s ease infinite;
}
.rudowealth_design {
  align-items: center;
  justify-content: center;
  padding: auto auto;
  width: 50%;
  flex-wrap: wrap;
  animation: animate 5s ease infinite;
}
.rudo_branding {
  width: 50%;
  align-items: center;
  justify-content: center;
  margin: 15% auto;
  display: block;
}
.rudo_branding_two {
  width: 70%;
  align-items: center;
  display: block;
  margin: 40% auto;
}
@keyframes changes {
  0% {
    background-color: aliceblue;
  }
  100% {
    background-color: #e75553;
  }
}
@keyframes animate {
  0% {
    background-color: #e75553;
  }
  100% {
    background-color: #1c1c1c;
  }
}
.typo_design {
  display: flex;
  background-color: gray;
  color: aliceblue;
  max-width: 80%;
  margin: auto;
  align-items: center;
}
.typo_img {
  width: 50%;
}
.typo_img_wrapper {
  width: 90%;
  margin: 10% 15%;
  align-items: center;
}
.type_para {
  width: 50%;
  align-items: center;
  margin: 10% 10%;
}
.type_para h5 {
  font-size: 24px;
  line-height: 2rem;
}
.circle_container {
  display: flex;
  margin: auto auto;
  width: 80%;
}
.epilogue {
  color: black;
  padding: 15% 7%;
  background-color: bisque;
  width: 50%;
  font-size: 60px;
}
.epilogue h2:hover {
  transform: scale(1.2);
}
.background_color {
  background-color: #e75553;
  position: relative;
  width: 50%;
}
.logo_container {
  position: absolute;
  top: 33%;
  left: 40%;
  width: 20%;
  align-items: center;
}
.circle {
  width: 60%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 20%;
  top: 11%;
  animation: mylogo 10s infinite linear;
}
.circle #round {
  fill: #000000 !important;
}
@keyframes mylogo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.b {
  border: 2px solid red;
}
.color {
  color: aliceblue;
  padding-left: 10%;
}
.illustration {
  font-size: 150px;
}
.pallate {
  font-size: 150px;
  background-image: linear-gradient(
    #e75553 0,
    #e75553 31%,
    #fff1d9 31%,
    #fff1d9 60%,
    #4abe9a 60%,
    #4abe9a 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.multicolor {
  margin-top: 10%;
}
.rudo_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10% 0;
}
.rudo_one {
  width: 40%;
  // animation: MoveToLeft 4s linear infinite alternate;
  cursor: pointer;
}
// @keyframes MoveToLeft {
//   0% {
//     transform: translateX(40px);
//   }
//   100% {
//     transform: translateX(-30px);
//   }
// }
.rudo_two {
  width: 40%;
  cursor: pointer;
  // animation: MoveToRight 4s linear infinite alternate;
}
// @keyframes MoveToRight {
//   0% {
//     transform: translateX(-30px);
//   }
//   100% {
//     transform: translateX(40px);
//   }
// }
.webdesign {
  color: aliceblue;
  width: 85%;
  margin: auto auto;
  text-align: center;
  line-height: 7rem;
}
.webdesign strong {
  font-size: 90px;
}
.star {
  display: flex;
}
.star_text {
  color: aliceblue;
  width: 40%;
  align-items: center;
  float: left;
  margin-left: 10%;
  margin: 10% 5%;
  line-height: 3rem;
}
.star_img {
  width: 40%;
  float: right;
  margin-left: 10%;
}
.Ui_design {
  background-color: #e75553;
  margin: auto;
  height: 100vh;
}
.text_color {
  color: aliceblue;
  width: 170%;
  margin-left: -11%;
  overflow: hidden;
  font-size: 64px;
  animation: rightToLeft 7s linear infinite;
  white-space: nowrap;
  text-transform: uppercase;
  position: absolute;
  top: 0;
}
@keyframes rightToLeft {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(-350px);
  }
}
.Vedio_container {
  position: relative;
}
article {
  max-width: 100%;
  margin-top: 10%;
  overflow: hidden;
}
.RUDO_TEXT {
  font-size: 64px;
}
.Financial_wrapper {
  display: flex;
}
.blue_container {
  background-color: #1c96a0;
  padding: 5% 6%;
  width: 50%;
}
.know_container {
  color: aliceblue;
  padding: auto auto;
  text-decoration: underline;
  font-size: 38px;
}
.mobile_one_img {
  width: 95%;
  margin: auto auto;
  padding: auto auto;
}
.know_text {
  font-size: 36px;
  width: 70%;
  line-height: 2.9rem;
}
.Financial_img {
  width: 50%;
}
.problem {
  margin: 12%;
  line-height: 2rem;
}
.problem h5 {
  color: aliceblue;
  font-size: 20px;
}
.instruction_container {
  padding-top: 15%;
}
.dots {
  width: 40px;
  height: 40px;
  background-color: #e75553;
  color: aliceblue;
  border-radius: 50%;
  padding-top: 11px;
  text-align: center;
  margin: auto;
}
.onboarding_wrapper {
  margin: auto auto;
  justify-content: center;
  padding: 10% 5%;
  text-align: center;
}
.onboarding_wrapper h3 {
  color: #1c96a0;
}
.onboarding_wrapper h5 {
  color: aliceblue;
  width: 30%;
  margin: auto auto;
  padding: 40px 0;
  line-height: 1.8rem;
  font-size: 22px;
}
.Financial_wrapper h3 {
  color: #1c96a0;
  text-align: center;
}
.instruction_wrapper h5 {
  color: aliceblue;
  margin: auto auto;
  text-align: center;
  width: 45%;
  line-height: 1.8rem;
  font-size: 22px;
}
.instruction_wrapper {
  width: 65%;
}
.questionnaire {
  width: 112%;
  margin-left: -5%;
}
.mobileresponsive {
  width: 70%;
  background-color: #1c96a0;
  border-radius: 35px;
  margin: 15% auto;
  display: flex;
  position: relative;
  margin-top: 15%;
}
.mobile_text {
  padding: 10% 5%;
  width: 45%;
}
.mobile {
  position: absolute;
  right: 0%;
  bottom: 5%;
}
.mobile_img {
  width: 70%;
}
.forms_container {
  background-color: #e75553;
  padding: 10% 0;
}
.forms_wrapper {
  display: flex;
  justify-content: space-around;
}

.forms_wrapper h4 {
  text-align: center;
  margin-top: 5%;
  font-size: 30px;
}
.bag_container {
  display: flex;
  width: 70%;
  margin: 5% auto;
  justify-content: space-around;
}
.thanks_container {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 40%;
  margin: 15% auto;
}
.thanks_container h2 {
  color: aliceblue;
  line-height: 8rem;
  margin-top: 4%;
}
.untill_wrapper {
  margin: 5% 0%;
  width: 100%;
}
@media only screen and (max-width: 1100px) {
  .epilogue {
    font-size: 2rem;
  }
  .forms_wrapper {
    flex-direction: column;
  }
  .typo_design {
    flex-direction: column;
    display: none;
  }
  .color img {
    width: 70%;
    margin: 0%;
  }
  .illustration {
    font-size: 50px !important;
  }
  .typo_img_wrapper {
    width: 80%;
    margin: 10% 10%;
  }
  .mobileresponsive {
    display: none;
  }
  .color {
    line-height: 6rem;
  }
  .epilogue h2 {
    font-size: 20px !important;
  }
  .pallate {
    font-size: 50px !important;
  }
  .onboarding_wrapper h5 {
    width: 80%;
    line-height: 2rem;
    font-size: 14px;
    padding: 13px 0;
  }
  .instruction_wrapper h5 {
    width: 105%;
    text-align: center;
    margin: 5% 23%;
    line-height: 2rem;
    font-size: 14px;
  }
  .instruction_wrapper h3 {
    margin: 5% 53%;
  }
  .webdesign strong {
    font-size: 20px !important;
  }
  .webdesign h2 {
    font-size: 20px !important;
    line-height: 2.8rem;
    width: 100%;
  }
  .rudo__wrapper ul {
    width: 80%;
    font-size: 1.1rem;
  }
  .rudo__wrapper ul li {
    min-width: 30%;
  }
  .star_text strong {
    font-size: 20px !important;
  }
  .star_text h3 {
    font-size: 18px !important;
    line-height: 2.8rem;
  }
  .star_text {
    width: 100%;
  }
  .star_img {
    display: none;
  }
  .text_color {
    display: none;
  }
  .thanks_container {
    width: 80%;
    margin: 10% auto;
  }
  .blue_container {
    width: 100%;
  }
  .know_container {
    font-size: 25px;
    margin: auto;
  }
  .Financial_wrapper {
    flex-direction: column;
    padding: 10px 0;
  }
  .dots {
    display: none;
  }
  .know_text {
    font-size: 30px;
    width: 100%;
  }
  .mobile_one_img {
    width: 80%;
    margin: 5% 8%;
    justify-content: center;
  }
  .Financial_img {
    width: 100%;
  }
  .thanks_container h2 {
    font-size: 24px;
  }
  .thanks_img {
    width: 20%;
  }
  .problem h5 {
    font-size: 16px !important;
  }
  .problem h3 {
    font-size: 18px !important;
  }
  .logo_img {
    width: 30%;
  }
  .logo_two_img {
    width: 70%;
  }
}
