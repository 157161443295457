.loader__wrapper {
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    color: #fff;
    display: grid;
    place-items: center;
    transition: opacity 1s ease-in-out;
    opacity: 0;
    animation: removeLoader 2.5s linear;
}

.tria {
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    width: 200px;
    height: 200px;
    display: grid;
    place-items: center;
    animation: scale_up 2s linear;
}


@keyframes scale_up {
    0% {
        transform:  scale(1);
        opacity: 1;
    }
    
    100%{
        transform: scale(1.4);
        opacity: 0;
    }
}

@keyframes removeLoader {
    0% {
       opacity: 1;
    }
    40%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        display: none;
    }
}