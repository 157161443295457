.accordion__row__container{
    cursor: pointer;
    width: 80%;
    margin: 3rem auto;

    hr{
        width: 100%;
        border: 1px solid gray;
        margin: 3.5rem 0;
    }
}

.accordion__row{
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5{
        font-size: 2rem;
        font-weight: bold;
    }
    
}


.accordion__colapse{
    -webkit-transition: height 1s ease;
    -moz-transition: height 1s ease;
    -o-transition: height 1s ease;
    transition: height 1s ease;
    height: 0;
    overflow: hidden;
}

.uncolapse{
    height: 70vh;
}

.accordion__children__container{
    width: 80%;
    margin: 0 auto;
    
    hr{
        width: 100%;
        border: 1px solid gray;
        margin: 3.5rem 0;
    }
}

.accordion__children__row{

    display: flex;
    align-items: center;
    justify-content: space-between;

    h6{
        font-size: 1.5rem;
    }

    a{
        padding: 1rem;
        display: flex;
        text-decoration: none;

        h5{
            padding-right: 1rem;
            font-size: 1.5rem;
        }

        img{
            transition: all 0.5s ease;
            width: 0;
        }

        &:hover{
            color: black;

            img{
                width: 45px;
            }
        }
    }
}