.home__footer {
  margin-top: 5%;
  display: flex;
  align-items: center;
}

.home__footer__container {
  width: 85%;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px !important;
}

.socials {
  img {
    width: 40px;
    height: 40px;
  }

  a {
    margin-right: 1.5rem;

    img {
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
