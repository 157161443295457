.form__master {
  padding: 10px;
  label {
    font-family: Access__Regular;
    font-size: 1.25rem;
  }
  input,
  textarea {
    margin: 10px 0 20px;
    border-color: transparent !important;
    outline-color: transparent !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 10px 14px 10px 0;
    font-size: 1.125rem !important;
    font-family: Access__Regular;
    resize: none;
    &:focus {
      box-shadow: 0 3px 0 0 rgba(0, 0, 0, 1);
    }
  }
}
