@font-face {
  font-family: "Satoshi";
  src: url(./assets/fonts/satoshi/Satoshi-Regular.woff2);
}
body {
  margin: 0;
  font-family: Satoshi !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-satoshi {
  font-family: "Satoshi", sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* //chat bot CSS */

.cc-ge4v {
  animation: none !important;
  transition: none !important;
}

.crisp-client .cc-kv6t .cc-1xry .cc-ge4v,
.crisp-client .cc-kv6t .cc-1xry .cc-unoo {
  animation-name: bounce1 !important;
  animation-duration: 1400ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  right: 30px !important;
  bottom: 30px !important;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce1 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
