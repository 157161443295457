.sinless__logo {
  width: 20%;
  margin: 20rem auto 20rem;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.flex__deo {
  margin: 2rem auto;
}

.bottom__margin2 {
  margin-bottom: 10rem;
}

.heading__sinless {
  text-align: center;
  margin-bottom: 10rem;

  h1 {
    font-size: 3.5rem;
  }
}

.website__button {
  font-family: Playfair__Bold;
  font-size: 2rem;
  width: 20rem;
  height: 8rem;
  margin: 10rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
}

.anchor:hover {
  color: black;
}

//forms
.dialog__box__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  background: rgba(175, 175, 175, 0.502);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.dialog__box {
  width: 40%;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 0px 5px #000;
  text-align: center;
  padding: 1%;
}

.cross__form {
  position: absolute;
  right: 5%;
  top: 5%;
  width: 30px;
  height: 30px;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    color: grey;
  }
}

.d__none {
  display: none;
}

.Form {
  width: 60%;
  margin: 0% auto;
  overflow: hidden;
  padding: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  padding: 3% 2%;
  margin-top: 3%;
  background-color: transparent;
  outline: none;
}

.btn {
  border: 2px solid black;
  width: 100%;
  padding: 1%;
  border-radius: 8px;
  margin: 3% auto;
  line-height: 1.2;
  font-weight: bolder;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}
