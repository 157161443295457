.ima2020__logo{
    width: 60%;
    margin: 15rem auto;

    img{
        width: 100%;
        object-fit: contain;
    }
}

.ima__icon{
    width: 15%;
    margin: 15rem auto;

    img{
        width: 100%;
        object-fit: contain;
    }
}


.graph{
    width: 80%;
    margin: 15rem auto;

    img{
        width: 100%;
        object-fit: contain;
    }
}

.flag{
    margin-bottom: 2rem;
}

.stage{
    margin-bottom: 10rem;
}
