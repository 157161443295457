.dn99_main_wrapper {
  .page__heading__container {
    height: fit-content;
  }
}
.dn99_thanks_for {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.dn99_thanks_for {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}