.work__container {
  width: 80%;
  margin: 7rem auto;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  &:hover {
    .view__arrow {
      a {
        opacity: 1;
        transition: all 0.5s ease-in-out;
        visibility: visible;
        // height: 50px;
      }
    }
    .work__image {
      img {
        transform: scale(1.04, 1.04);
      }
    }
  }
}

.reverse {
  flex-direction: row-reverse;
}

.padding__bottom {
  padding-bottom: 8rem;
}
.padding__top {
  padding-top: 8rem;
}

.work__image {
  width: 60%;
  padding-right: 2rem;

  img {
    transition: all 0.5s ease-out;
    width: 100%;
    object-fit: contain;
    overflow: auto;
    z-index: -2;
  }
}

.work__text {
  padding-left: 2rem;
  padding-right: 2rem;
  width: 40%;
  h5 {
    padding-bottom: 1rem;
    color: #999999;
    font-size: 2rem;
  }
  h3 {
    font-size: 4rem;
  }
}

.view__arrow {
  a {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    visibility: hidden;
    // height: 0;

    h5 {
      color: rgb(12, 12, 12);
      padding-right: 1rem;
    }
  }
}

//work Tabs

.work__tab__container {
  float: right;
  .lis {
    display: inline;
    cursor: pointer;
    border: 2px solid red;
  }

  .lis .active {
    border: 2px solid green;
    border-radius: 10px;
  }
}