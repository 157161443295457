
.prev_next_work_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15%;
    gap:2rem;
    h3{
        font-weight: 300;
    }
}
.prev_next_work_box_left{
    width: 50%;
    padding: 14% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 1px;
    transition: all 400ms ease-in-out;
    h3{
        color:white;
        transition: opacity 400ms ease-in-out;
    }
}

.prev_next_work_box_left:hover{
    .image_bg{
        transform: scale(1.2);
        filter: blur(0px);
        &::before{
            opacity: 0;
        }
    }
    h3{
        opacity: 0;
    }
}

.image_bg{
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    transition: all 400ms ease-in-out;
    transform: scale(1.1);
    filter: blur(5px);
    &::before{
        content: "";
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: linear-gradient(rgba(255, 255, 255, 0),rgba(0, 0, 0, 0.589));
        transition: all 400ms ease-in-out;
    }
}

.prev_hr{
    width: 1px;
    background: linear-gradient(to bottom ,white, grey, white); 
}
.prev_next_work_box_right{
    width: 50%;
    padding: 14% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 1px;
    transition: opacity 400ms ease-in-out;
    h3{
        color:white;
        transition: opacity 400ms ease-in-out;
    }
}

.prev_next_work_box_right:hover{    

    .image_bg{
        transform: scale(1.2);
        filter: blur(0px);
        &::before{
            opacity: 0;
        }
    }
    h3{
        opacity: 0;
    }
}

.images{
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width:786px) {
    .prev_next_work_container{
        gap: 0rem;
    }
}