@font-face {
  font-family: "Access-regural";
  src: url(../assets/Hellix-Regular.woff2);
  font-weight: "normal";
}

.fontAccess {
  font-family: "Access-regural";
}

.swan_container {
  background-color: #f4f4f4;
  width: 80%;
  margin: 5% auto;
}

.swan_banner:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
}

.goal_wrapper {
  width: 95%;
  margin: auto auto;
  text-align: center;
  padding: 100px 0;
}

.goal_wrapper h2 {
  font-size: 60px;
  line-height: 4.2rem;
}

.goal_wrapper h4 {
  line-height: 3rem;
}

.product_container {
  background-color: #000000;
  width: 80%;
  margin: 5% auto;
  color: aliceblue;
}

.market_wrapper {
  border: 3px solid #eb6725;
  border-radius: 10px;
  width: 48%;
  margin: 5% auto;
}

.goal_img_wrapper {
  margin: 5% auto;
  width: 80%;
}

.color_wrapper {
  margin: 5% auto;
}

.iconography_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8% auto;
}

.homepage_wrapper {
  background-color: #fff3ed;
  width: 80%;
  margin: 5% auto;
}

.testimonial_wrapper {
  width: 80%;
  margin: auto auto;
  display: flex;
  justify-content: space-around;
}

.width {
  width: 35%;
}

.home_page {
  margin-top: 13%;
}

.testimonial {
  width: 85%;
  margin: auto auto;
}

.Thanks_swan {
  padding: 20% 0;
  text-align: center;
}

@media only screen and (max-width: 1100px) {
  .goal_wrapper h2 {
    font-size: 24px;
    line-height: 3rem;
  }

  .goal_wrapper h4 {
    line-height: 2.4rem;
    font-size: 18px;
  }

  .market_wrapper {
    width: 100%;
    font-size: 18px;
  }

  .iconography_wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .width {
    width: 70%;
  }

  .testimonial_wrapper {
    flex-direction: column;
    margin: auto auto;
    width: 80%;
    gap: 30px;
  }

  .testimonial {
    margin: auto 8%;
  }
}
