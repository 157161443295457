* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
//  temp

@font-face {
  font-family: regular;
  src: url(./assets/fonts/satoshi/Satoshi-Regular.woff2);
}
.check {
  font-family: "regular", sans-serif !important;
}
//css reset

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  scroll-behavior: smooth;
}

ol,
blockquote,
q {
  quotes: none;
}

blockquote {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {
  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

//fonts

// @font-face {
//   font-family: Satoshi;
//   src: url("./assets/fonts/Playfair-Family/playfairdisplay-bold-webfont.woff");
//   font-weight: bold;
// }

// @font-face {
//   font-family: Playfair__Bolditalic;
//   src: url("./assets/fonts/Playfair-Family/playfairdisplay-bolditalic-webfont.woff");
//   font-weight: bold;
// }

// @font-face {
//   font-family: Satoshi;
//   src: url("./assets/Hellix-Regular.woff2");
//   font-weight: normal;
// }

// @font-face {
//   font-family: Access__Bold;
//   src: url("./assets/Hellix-Bold.woff2");
//   font-weight: bold;
// }

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: Satoshi;
  font-size: 12rem;
  font-weight: bold;
}

h2 {
  font-family: Satoshi;
  font-size: 6rem;
  font-weight: bold;
}

h3 {
  font-family: Satoshi;
  font-size: 3rem;
  font-weight: bold;
  line-height: 4rem;
}

h4 {
  font-family: Satoshi;
  font-size: 2rem;
  font-weight: bold;
}

h5 {
  font-family: Satoshi;
  font-size: 1.1rem;
  font-weight: normal;
}

h6 {
  font-family: Satoshi;
  font-size: 1rem;
  font-weight: normal;
}

input {
  font-family: Satoshi;
}
p {
  font-family: Satoshi;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 2.5rem;
}

a {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.service__list__container {
  width: 80%;
  margin: 0 auto;
}

.master__container {
  width: 85%;
  margin: 10px auto 0;
}

.work {
  width: 85%;
  margin: 0 auto 10px;
  padding: 10rem 0 0 1rem;
}

.main__heading {
  margin: 5rem 0;
}

.placeholder__dark {
  background: black;
  height: 100vh;
  width: 100%;
}

hr {
  background-color: black;
}

// scroll bar
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 16px rgba(134, 134, 134, 0.7);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(19, 19, 19);
  outline: 1px solid rgb(255, 255, 255);
  border-radius: 20px;
}

// variable fonts

html {
  font-size: 10px;
}
@media screen and (min-width: 640px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 1080px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 1280px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1450px) {
  html {
    font-size: 16px;
  }
}

.cc-1c9v {
  .crisp-client .cc-kv6t .cc-1ada,
  .crisp-client .cc-kv6t .cc-ew5j:before,
  .crisp-client .cc-kv6t .cc-ew5j:after {
    background-color: red !important;
  }
}

.crisp-client .cc-kv6t .cc-vuur,
.crisp-client .cc-kv6t .cc-7rgf[data-active="true"] {
  background: red !important;
}

// PAGE
@import "./Styles/AboutPage";
@import "./Styles//ContactPage";
@import "./Styles/TeamPage";
@import "./Styles/ErrorPage";

//partials
@import "./Styles/NavBar";
@import "./Styles/HomeFooter.scss";
@import "./Styles/HomeSlider";
@import "./Styles/WorkPortfolios";
@import "./Styles/Footer";
@import "./Styles/PageHeading";
@import "./Styles/OurCulture";
@import "./Styles/TeamCard";
@import "./Styles//Accordion";
@import "./Styles/Form";
@import "./Styles/Responsives/Responsive";
@import "./Styles/PrevAndNextWork";
@import "./Styles/FlickingCarousel";
@import "./Styles/TabView";
@import "./Styles/NewPortfolio";
@import "./Styles/ContacUsCards";
@import "./Styles/Loader";
@import "./PortfoliosStyles/Main";

//Atoms Styles
@import "./Styles/AtomStyles/AllCards";
@import "./Styles/AtomStyles/Images";
@import "./Styles/AtomStyles/WebsiteGlimpse";
@import "./Styles/AtomStyles/PagerHeading";

//portfolio Responsive
@import "./Styles/Responsives/PortfoliosResponsive";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
