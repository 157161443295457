.main_container {
  background-color: #e3334a;
}
.shopx_wrapper {
  width: 80%;
  margin: auto;
  padding-top: 5rem;
}
.shopx_logo {
  width: 45%;
  height: 40%;
  margin-top: 10%;
  margin-left: 5%;
}
.shopx_text {
  padding-top: 3rem;
  width: 30%;
  margin-left: 5%;
  color: #ffffff;
}
.card_section {
  background-color: #fff5f6;
}

.card_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 370px));
  grid-gap: 25px 25px;
  justify-content: center;
  align-items: baseline;
  max-width: 1500px;
  margin: auto;
  padding: 90px 30px;
}
.yellow {
  background-color: #ffe992;
}
.orange {
  background-color: #ffcb9b;
}
.pink {
  background-color: #ffa9a9;
}
.lightblue {
  background-color: #45c1df;
}
.blue {
  background-color: #4a8af5;
}
.green {
  background-color: #76e6ba;
}
.card_grid_container {
  width: 95%;
  border-radius: 20px;
  height: 220px;
  line-height: 1.9rem;
  display: flex;
  justify-content: space-around;
  justify-items: center;
}
.card_grid_container:hover {
  filter: contrast(0.8);
}
.card_grid_container h4 {
  font-size: 24px;
  padding: 0 25px;
  padding-top: 30px;
}
.card_grid_container h5 {
  padding: 0 7px;
  background-color: #ffffff;
  color: #ea1f3d;
  border-radius: 20px;
  width: 55%;
  margin: 10px 25px;
  text-align: center;
}
.Wallet_wrapper {
  padding: 0 20px;
  width: 50%;
  padding-bottom: 30px;
  object-fit: contain;
}
.banner {
  background-image: url(../assets/shopXappAssets/shopxBarcode.png),
    radial-gradient(
      circle at center,
      #fdb913 0,
      #fdb913 50%,
      transparent 50%,
      transparent 100%
    ),
    linear-gradient(to right, #e3334a, #e3334a);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: contain, 70% auto, 100% 100%;
  background-position: center center, center center, center center;
  place-content: center;
  height: 96vh;
}
.shopx_barcode {
  width: 100%;
  margin: auto;
}
.grid-item {
  background-color: #ffcb9b;
  text-align: center;
  padding: 20px 0;
  width: 300px;
}
.heading_wrapper {
  padding: 5% 15%;
  color: #ffffff;
}
.heading_wrapper_right {
  padding: 5% 15%;
  width: 70%;
}
.heading_img {
  width: 15%;
}
.research_wrapper {
  display: flex;
  width: 60%;
  margin: auto;
  color: #ffffff;
}
.research_para {
  width: 65%;
  flex-wrap: wrap;
}
.strategy_wrapper {
  margin: auto;
  width: 90%;
  padding: 10%;
}
.reward_wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  margin: auto;
}
.reward_img_wrapper {
  width: 40%;
  float: left;
  height: 60%;
}
.reward-img {
  width: 100%;
  height: 40%;
  margin-top: 15%;
}
.rewards_right {
  width: 45%;
  height: 30%;
  margin-top: 10%;
  padding-left: 4rem;
}
.rewardsecond_left {
  width: 60%;
  height: 30%;
  margin: 5% 0;
  text-align: right;
  padding: 0 10rem;
}
.rewardSecondimg_wrapper {
  width: 35%;
  height: fit-content;
  margin-bottom: 10%;
}
.expertation_wrapper {
  width: 50%;
  padding: 5% 0;
  color: #ffffff;
  float: right;
}
.expertation_wrapper h3 {
}
.chart_wrapper {
  max-width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
}
.text_wrapper {
  color: #241a1b;
}
.research_wrapper_black {
  display: flex;
  width: 60%;
  margin: auto auto;
}
.research_para_black {
  width: 65%;
  span {
    color: #fdb913;
  }
}
.background_grid {
  background-color: #e3334a;
  width: 80%;
  margin: auto;
  border-radius: 2rem;
  margin-top: -10%;
}
.grid_wrapper {
  text-align: center;
}

.background_container {
  width: 100%;
}
.circle_wrapper {
  position: relative;
  margin: 10% 0;
}
.circle_red {
  position: absolute;
  bottom: 80%;
  left: 5%;
  width: 10%;
}
.circle_yellow {
  position: absolute;
  top: 84%;
  right: 5%;
  width: 10%;
}
.para_wrapper {
  text-align: center;
  margin-top: 4rem;
}
.concept_wrapper {
  padding-top: 5rem;
  font-size: 9rem;
}
.concept_img {
  margin-top: -4rem;
}
.cong_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.concept_first {
  width: 20%;
  margin-top: 10%;
}
.concept_second {
  width: 20%;
  margin-left: 10rem;
  margin-top: 20%;
}
.concept_third {
  width: 22%;
  padding: 3rem;
}
.american_women {
  position: relative;
  margin: 10% 0;
}
.circle_yellow_second {
  position: absolute;
  top: 85%;
  left: 5%;
  width: 10%;
}
.circle_red_second {
  position: absolute;
  bottom: 80%;
  right: 5%;
  width: 10%;
}
.scanner_wrapper {
  display: flex;
  margin-top: 10%;
  width: 110%;
  justify-content: center;
  margin-left: 2rem;
  gap: 2rem;
}
.scanner_container {
  overflow: hidden;
}
.concept_fourth {
  margin: auto;
}
.spinning_wrapper {
  display: flex;
  margin-top: 4rem;
  margin-left: 5rem;
  gap: 2rem;
}
.spinning_para {
  width: 35%;
  margin-top: 30%;
  margin-left: 10%;
}
.indian_women {
  position: relative;
  margin: 10% 0%;
}
.yellow_indian_women {
  position: absolute;
  bottom: 80%;
  right: 5%;
  width: 10%;
}
.red_indian_women {
  position: absolute;
  top: 82%;
  left: 5%;
  width: 10%;
}
.scanner_wrapper_left {
  display: flex;
  margin-left: -7%;
}
.concept_fivth {
  margin-right: 4rem;
}
.store_container {
  position: relative;
  margin: 10% 0%;
}
.colorcode {
  justify-content: center;
  margin: 10% auto;
  display: block;
  width: 80%;
}
.circle_red_fourth {
  position: absolute;
  top: 83%;
  right: 5%;
  width: 10%;
}
.newgrocery_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
.applicationchart {
  justify-content: center;
  margin: 5% 10%;
  width: 80%;
}
.shopxhand {
  margin-left: -18%;
  width: 135%;
}
.Shopxhand_wrapper {
  background-color: #241a1b;
  overflow: hidden;
}
.supersaver {
  background-color: #e3334a;
}
.text_super {
  font-size: 4rem;
  text-align: center;
  color: #fff5f6;
  margin: auto;
  padding: 8rem 0;
  width: 40%;
}
.grid {
  display: block;
}
.multidevice {
  background-color: #241a1b;
  position: relative;
}
.multidevice_wrapper {
  padding-top: 10%;
}
strong {
  color: #e3334a;
  font-size: 2.7rem;
}
.screens {
  color: #ffffff;
  font-size: 2.7rem;
}
.text_screen {
  display: flex;
  position: absolute;
  padding-top: 11%;
  padding-left: 17%;
  gap: 13%;
}
.text_days {
  position: absolute;
  padding-top: 19%;
  padding-left: 17%;
}
.thank_wrapper {
  color: #fff5f6;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 10% 0;
}
.thanku_circle {
  width: 100%;
  height: 50vh;
}
.thankyou_circle {
  width: 100%;
}
.coffeecard {
  position: absolute;
  bottom: 20%;
  right: 80%;
  width: 20%;
}
.coin {
  position: absolute;
  top: 20%;
  right: 68%;
  width: 10%;
}
.brand {
  position: absolute;
  top: 20%;
  left: 68%;
  width: 20%;
}
.coin_two {
  position: absolute;
  bottom: 10%;
  right: 0;
  width: 10%;
}
.Thank_mobile {
  display: none;
}
.newgrocery {
  width: 90%;
  padding: 10% auto;
}
@media only screen and (max-width: 1100px) {
  .shopx_text {
    width: 100%;
  }
  .colorcode {
    width: 80vw;
  }
  .heading_wrapper {
    padding: 15px;
  }
  .heading_text {
    width: 100%;
  }
  .Wallet_wrapper {
    width: 50%;
    height: 25vh;
  }
  .concept_img {
    width: 90%;
  }
  .concept_first {
    width: 70%;
  }
  .concept_second {
    width: 70%;
    margin: auto auto;
  }
  .concept_third {
    width: 84%;
    margin: auto auto;
  }
  .barcode_wrapper {
    margin: 0%;
  }
  .card_container {
    grid-template-columns: auto;
    padding: 30px 40px;
    margin: auto;
  }
  .card_grid_container {
    width: 300px;
    line-height: 2.4rem;
  }
  .banner {
    background-image: url(../assets/shopXappAssets/shopxBarcode.png),
      radial-gradient(
        circle at center,
        #fdb913 0,
        #fdb913 60%,
        transparent 50%,
        transparent 100%
      ),
      linear-gradient(to right, #e3334a, #e3334a);
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: contain, 80% auto, 100% 100%;
    background-position: center center, center center, center center;
    place-content: center;
    height: 37vh;
  }
  .circle_red {
    display: none;
  }
  .circle_yellow {
    display: none;
  }
  .circle_yellow_second {
    display: none;
  }
  .circle_red_second {
    display: none;
  }
  .research_wrapper {
    width: 80%;
    flex-direction: column;
    margin: auto;
  }
  .research_para {
    width: 100%;
    margin-top: 5%;
  }
  .research {
    width: 100%;
  }
  .reward_wrapper {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }
  .thank_wrapper {
    display: none;
  }
  .rewards_right {
    width: 100%;
    margin: 0%;
  }
  .rewardsecond_left {
    width: 100%;
    margin-top: 10%;
    padding: 0%;
  }
  .expertation_wrapper {
    width: 90%;
    margin: auto;
    margin-left: 5%;
  }
  .barcode_wrapper {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  .heading_wrapper {
    width: 90%;
    margin-left: 5%;
  }
  .text_wrapper {
    width: 100%;
    color: #241a1b !important;
  }
  .research_wrapper_black {
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  .research_para_black {
    width: 100%;
    margin-top: 5%;
  }
  .circle_wrapper {
    height: 20vh;
  }
  .american_women {
    height: 20vh;
  }
  .scanner_wrapper {
    width: 70%;
    margin: auto auto;
    flex-direction: column;
  }
  .spinning_wrapper {
    flex-direction: column;
    margin: auto auto;
    width: 80%;
  }
  .Thank_mobile {
    display: block;
    text-align: center;
    align-items: center;
    padding: 40px 0;
    animation: fadeIn 5s;
  }
  .red_indian_women {
    display: none;
  }
  .yellow_indian_women {
    display: none;
  }
  .circle_red_fourth {
    display: none;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }
  .spinning_para {
    width: 100%;
    margin-top: 0;
  }
  .Arrow_first {
    display: none;
  }
  .Arrow_second {
    display: none;
  }
  .applicationchart {
    width: 80vw;
    margin-left: 8%;
  }
  .newgrocery {
    width: 80vw;
  }
  .thank_wrapper {
    height: 50vh;
    width: 100%;
  }
  .brand {
    left: 0;
  }
  .text_screen {
    padding: 0%;
    margin: 2% 3%;
  }
  .text_super {
    width: 100%;
  }
  .circle_red {
    bottom: 26%;
  }
  .circle_red_fourth {
    top: 72%;
    width: 15vw;
  }
  .store_container {
    height: 16vh;
  }
  .scanner_wrapper_left {
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  .indian_women {
    height: 16vh;
  }
  .red_indian_women {
    width: 15vw;
    top: 70%;
  }
  .yellow_indian_women {
    width: 15vw;
    bottom: 70%;
  }
  .reward_img_wrapper {
    width: 100%;
  }
  .rewardSecondimg_wrapper {
    width: 100%;
  }
}
.b {
  border: 2px solid red;
}
