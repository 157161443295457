@font-face {
  src: url(../assets/fonts/Poppins-Regular.ttf);
}

.blade1__wrapper {
  position: relative;
  height: 95vh;
  min-height: 400px;
  background-image: url(../assets/brebelBranding/blade1/foreImg.png),
    url(../assets/brebelBranding/blade1/bgImg.jpg);
  background-repeat: no-repeat, no-repeat;
  background-size: contain, 150% 100%;
  background-position: center bottom, center center;
  transition: background-size 300ms;

  &:hover {
    background-size: contain, 200% 150%;
  }
  @media screen and (max-width: 768px) {
    height: 400px;
  }
}

.blade2__wrapper {
  padding-left: 10vw;
  padding-top: 120px;
  padding-bottom: 200px;
  overflow: hidden;
  position: relative;
  .bgImg {
    position: absolute;
    top: auto;
    bottom: -150px;
    left: auto;
    right: -150px;
    animation: rotateBg__brebelBranding 15s linear infinite;
  }

  h3 {
    font-size: 2.5rem;
    padding-bottom: 1rem;

  }

  p {
    font-size: 1.5rem;
    line-height: 1.5;

    max-width: 1000px;
    width: 80%;
    display: block;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px 0 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: right -80px bottom -100px;
    background-size: 200px 200px;

    h3 {
      font-size: 2.5rem;
      padding-bottom: 3rem;
    }

    p {
      font-size: 1.25rem;
      width: 100%;
    }
    .bgImg {
      display: none;
    }
  }
}
.card_art {
  min-height: 550px;
  background-image: url("../assets/brebelBranding/noise.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: center center;
  display: grid;
  place-content: center;
  gap: 250px;
  padding-bottom: 100px;
  @media screen and (max-width: 768px) {
    gap: 100px;
    img {
      min-height: 100px;
      object-fit: contain;
    }
  }
}
.blade3__wrapper {
  position: relative;
  height: 100vh;
  min-height: 450px;
  background-image: url(../assets/brebelBranding/blade3/text.png),
    url(../assets/brebelBranding/blade3/bg.png);
  background-position: left 10vw top 100px, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto 300px, cover cover;

  @media screen and (max-width: 768px) {
    background-size: auto 150px, cover;
    height: 80vh;
    background-position: left 5vw top 70px, right bottom;
  }

  @media screen and (max-width: 512px) {
    background-size: auto 100px, cover;
    height: 70vh;
    background-position: left 3vw top 50px, right bottom;
  }
}
.blade4__wrapper {
  background-image: url("../assets/brebelBranding/noise.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 100px;
  padding-bottom: 100px;

  video {
    width: 80%;
    object-fit: cover;
    border-radius: 15px;
    margin: 0 auto;
  }
  .text__wrapper {
    width: 80%;
    margin: 130px auto 20px;
    color: white;
    display: flex;
    gap: 30px;
    flex-direction: column;
    h5 {
      font-size: 2.5rem;
  
    }
    span {
      font-size: 1.5rem;
  
      color: white;
      line-height: 1.5;
      max-width: 950px;
      width: 85%;
    }
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
    padding-bottom: 7rem;
    .text__wrapper {
      width: 90%;
      margin: 5rem auto 5rem;
      gap: 2rem;
    }
    video {
      width: 90%;
    }
  }
}
.blade6__wrapper {
  padding: 250px 10px 100px 10px;
  background-color: black;
  background-image: url("../assets/brebelBranding/noise.png");
  background-size: cover cover;
  background-origin: left top;

  .text__wrapper {
    width: 80%;
    margin: 0 auto;
    color: white;
    display: flex;
    gap: 30px;
    flex-direction: column;
    h5 {
      font-size: 2.5rem;
  
    }
    span {
      font-size: 1.5rem;
  
      color: white;
      line-height: 1.5;
      max-width: 950px;
      width: 85%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 5rem 0;
    .text__wrapper {
      width: 90%;
      gap: 2rem;
    }
  }
}
.blade7__wrapper {
  background-image: url("../assets/brebelBranding/blade7/bgGrid.png"),
    url("../assets/brebelBranding/noise.png");
  background-size: cover, cover;
  background-position: left top, left top;
  background-repeat: no-repeat;
  min-height: 90vh;
  padding: 5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  .left,
  .right {
    flex: 1 1 350px;
    max-width: 600px;
    display: grid;
    place-content: center;
    padding: 10px;
    min-height: 600px;
    border-radius: 20px;
  }
  .left {
    background-color: #ec2f32;
    img {
      animation: rotateBrebelImages 7s infinite linear !important;
    }
  }
  .right {
    background-color: #ffffff;
    img {
      animation: rotateBrebelImages 7s infinite linear !important;
    }
  }
  @media screen and (max-width: 768px) {
    .left,
    .right {
      min-height: 400px;
    }
  }
}
.blade8__wrapper {
  .img__wrapper {
    img {
      min-height: 450px;
      object-fit: cover;
      object-position: center;
    }
  }
}
.blade9__wrapper {
  padding: 130px 10px 100px 10px;
  background-color: black;
  background-image: url("../assets/brebelBranding/noise.png");
  background-size: cover cover;
  background-origin: left top;

  .text__wrapper {
    width: 80%;
    margin: 0 auto;
    color: white;
    padding-bottom: 100px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    h5 {
      font-size: 2.5rem;
  
    }
    span {
      font-size: 1.5rem;
      color: white;
      line-height: 1.5;
      max-width: 950px;
      width: 85%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 5rem 0;
    .text__wrapper {
      width: 95%;
      gap: 2rem;
      padding-bottom: 4rem;
    }
  }
}
.blade10__wrapper {
  background-image: url("../assets/brebelBranding/noise.png");
  display: flex;
  padding-top: 130px;
  flex-direction: column;
  gap: 100px;
  .text__wrapper {
    width: 80%;
    margin: 0 auto;
    color: white;
    display: flex;
    gap: 30px;
    flex-direction: column;
    h5 {
      font-size: 2.5rem;
    }
    span {
      font-size: 1.5rem;
      color: white;
      line-height: 1.5;
      max-width: 950px;
      width: 85%;
    }
  }

  .img__wrapper {
    background-image: url("../assets/brebelBranding/blade10/BG.png");
    background-size: cover cover;
    background-origin: left top;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    min-height: 400px;
    padding-top: 12px;
    gap: 1rem;
    .text__wrapper {
      gap: 2rem;
      padding: 5rem 0 0;
      width: 90%;
    }
    .img__wrapper {
      min-height: 250px;
      margin-top: 20px;
    }
  }
}
.ribbon__image {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.brebelBranding__img {
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    min-height: 400px;
  }
}
@keyframes rotateBg__brebelBranding {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateBrebelImages {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
