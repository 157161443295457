@font-face {
  src: url(../assets/fonts/Urbanist-VariableFont_wght.ttf);
  font-weight: 'normal';
}
// .hero_section {
//   background-image: url(../assets/fasalAssets/hero_sectionbg.png);
//   background-repeat: no-repeat;
//   background-size: 100%;
// }
// .video_section {
//   width: 75%;
//   margin: auto auto;
//   padding: 10% 0;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   position: relative;
// }
// .fasal_vedio {
//   position: absolute;
//   bottom: 17%;
// }

.header_img {
  width: 92%;
  margin: auto 4%;
}
.urbanist_container {
  background-color: #f8f8f8;
}
.typography {
  width: 70%;
  margin: 5% auto;
  text-align: center;
  padding: 9% 0;
}
.typography h4 {
  color: #00613c;
}
.typography h3 {
  padding: 40px 0;
  font-size: 38px;
}
.urbanist_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.urbanist_wrapper h1 {
  font-size: 220px;
  text-align: center;
  color: #540032;
}
.title {
  background-image: url(../assets/fasalAssets/hero_sectionbg.png);
  background-attachment: fixed;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.urbanist_letter {
  margin: 2% 0;
  line-height: 4rem;
  font-size: 38px;
  word-spacing: 2rem;
}
.fasal_cards {
  display: flex;
  padding: 10% 0;
  width: 70%;
  margin: 5% auto;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.fasal_logo {
  width: 60%;
}
.color_green {
  width: 100%;
}
.fasal_green {
  background-color: #02613c;
  padding: 80px 0;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto auto;
}
.fasal_white {
  background-color: #ffffff;
  padding: 80px 0;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto auto;
}
.fasal_brown {
  background-color: #c9573f;
  padding: 80px 0;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto auto;
}
.fasal_light {
  background-color: #9dc42b;
  padding: 80px 0;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto auto;
}
.fasal_beige {
  background-color: #f3f3f3;
  padding: 80px 0;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto auto;
}
.fasal_black {
  background-color: #333333;
  padding: 80px 0;
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto auto;
}
.colorpalette {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}

.colorpalette h1 {
  color: #f3f3f3;
}
.colorpalette h3 {
  color: #00613c;
}
.colorimg {
  display: flex;
  width: 50%;
  margin: auto auto;
  margin-top: -3%;
  justify-content: space-evenly;
  gap: 5%;
}
.fasal_icon {
  width: 35%;
  padding: 5% 0;
}
.screen_wrapper {
  background-image: url(../assets/fasalAssets/MaskGroup23.png);
}
.screens {
  // width: 35%;
  margin: auto 15%;
  padding: 7% 0;
}
.screens p {
  color: #000000;
  margin: 2% 0;
  font-size: 20px;
  line-height: 2rem;
}
.screens h2 {
  color: #000000;
  margin: 2% 2%;
  font-size: 60px;
}
.screens h4 {
  color: #00613c;
  font-size: 28px;
  margin: 5% 0;
}
.screens_social {
  // width: 35%;
  margin: auto 15%;
  padding: 7% 0;
  align-items: center;
}
.highfidelity {
  width: 100%;
  margin-top: -10%;
}
.instagram_wrapper {
  position: relative;
}
.screen_border {
  display: flex;
  gap: 1%;
}
.vertical_line {
  border-left: 7px solid #00613c;
  height: 60px;
  border-radius: 6px;
  margin-top: 1.5rem;
}
.side_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
}
.side_left_img {
  position: absolute;
  top: 3;
  left: 0;
  width: 20%;
}
.vertical_line_bggreen {
  border-left: 7px solid #ffffff;
  height: 60px;
  border-radius: 6px;
  margin-top: 1.2rem;
}
.sm_wrapper {
  background-color: #00613c;
  overflow: hidden;
}
.screens_social p {
  color: #ffffff;
  margin: 2% 0;
  font-size: 20px;
  line-height: 2rem;
}
.screens_social h2 {
  color: #ffffff;
  margin: 2% 2%;
  font-size: 60px;
}
.screens_social h4 {
  color: #ffe366;
  margin: 5% 0;
  font-size: 28px;
}
.instagram_post {
  display: flex;
  width: 90%;
  margin: auto 22%;
  gap: 5%;
  text-align: right;
}
.instagram1 {
  width: 25%;
}
.instagram_post2 {
  display: flex;
  width: 90%;
  margin: 7% 1.5%;
  text-align: left;
  gap: 5%;
}
.background_section {
  background-image: url(../assets/fasalAssets/hero_sectionbg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 7% 0;
}
.video_section {
  background-color: #fff;
  width: 60%;
  margin: auto auto;
  border-radius: 2.5rem;
  position: relative;
  padding: 1% 1%;
}
.fasal_vedio {
  margin: 2px auto;
  border-radius: 1.6rem;
}
.instagram_carousel_img_one {
  width: 100%;
  margin: auto auto;
}
.instagram_carousel {
  margin: auto 35%;
  width: 65%;
}
.instagram_carousel_two {
  width: 70%;
  margin: 5% 0;
}
.instagram_carousel_img_three {
  width: 100%;
  margin: auto auto;
}
.instagram_post_video_wrapper {
  width: 100%;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.instagram_post_video video {
  border-radius: 5%;
  width: 100%;
}
.instagram_post_video {
  position: absolute;
  top: 3.2%;
  left: 39%;
  right: 0;
  bottom: 0;
  width: 16%;
  max-width: 100%;
  overflow: hidden !important;
  z-index: -1;
  transform: rotate(0.4deg);
}
.social_media {
  width: 92.4%;
  margin: auto;
}
.social_media_vedio {
  width: 30%;
  margin: 0 auto;
}
.instagram_video {
  width: 400px;
  margin: auto auto;
  z-index: -1;
}
.fasal_thankyou {
  text-align: center;
  background-color: #00613c;
  padding: 15% 0;
  color: #fff;
  background-image: url(../assets/fasalAssets/thankyou_background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25%;
}
.Social_media_container {
  background-image: url(../assets/fasalAssets/fasalsidebackgrounds.png),
    url(../assets/fasalAssets/fasalsidebackground2.svg);
  background-position: right top, left bottom;
  background-repeat: no-repeat;
  background-size: 20%;
}
@media only screen and (max-width: 1100px) {
  .fasal_cards {
    width: 90%;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .fasal_green {
    width: 40%;
    padding: 40px 0;
  }
  .fasal_black {
    width: 40%;
    padding: 40px 0;
  }
  .screens {
    width: 83%;
    margin: auto 7%;
  }
  .screens h4 {
    font-size: 20px;
  }
  .instagram_carousel_two {
    width: 100%;
    margin: 5% 0;
  }
  .screens_social h4 {
    font-size: 20px;
  }
  .colorpalette h3 {
    font-size: 20px;
  }
  .vertical_line {
    display: none;
  }
  .vertical_line_bggreen {
    display: none;
  }
  .screens_social p {
    font-size: 15px;
  }
  .typography {
    width: 90%;
    margin: 5% auto;
    text-align: center;
    padding: 10% 0;
  }
  .instagram_carousel {
    margin: auto 0;
    width: 100%;
  }
  .side_left_img {
    display: none;
  }
  .instagram_post {
    flex-direction: column;
    margin: 3% auto;
  }
  .instagram_post2 {
    flex-direction: column;
    margin: 3% auto;
  }
  .side_img {
    display: none;
  }
  .screens_social h2 {
    font-size: 30px;
  }
  .screens h2 {
    font-size: 30px;
  }
  .screens p {
    font-size: 15px;
  }
  .screens_social {
    width: 83%;
    margin: auto 7%;
  }
  .fasal_icon {
    width: 55%;
  }
  .fasal_light {
    width: 40%;
    padding: 40px 0;
  }
  .typography h3 {
    padding: 40px 0;
    font-size: 24px;
  }
  .urbanist_letter {
    line-height: 3.2rem;
    font-size: 24px;
    word-spacing: 0.6rem;
  }
  .fasal_beige {
    width: 40%;
    padding: 40px 0;
  }
  .fasal_white {
    width: 40%;
    padding: 40px 0;
  }
  .fasal_brown {
    width: 40%;
    padding: 40px 0;
  }
  .instagram1 {
    width: 70%;
    margin: auto;
  }
}
