.team__image__container {
  width: 100%;
  height: 75vh;
  margin-top: 10%;
  .img__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .img__bg {
      height: 100%;
      width: 100%;
      margin: 0 auto;
      background-image: url("../assets/images/team_image.jpg");
      background-repeat: left top;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
      overflow: hidden;
      width: 90%;
    }

    // .cursor__overlay {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   height: 200px;
    //   width: 200px;
    //   // background: url("../assets/images/team_grayscale.webp");
    //   background-color: red;
    //   border: 1px solid red;
    //   z-index: 1;
    //   border-radius: 50%;
    // }
  }
  @media screen and (max-width: 768px) {
    height: 300px;

    .img__bg {
      width: 95%;
    }
  }
}

.team__member__grid {
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
