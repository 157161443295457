.portfolio__container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  gap: 7rem;
  .portfolio__content {
    z-index: 9999999;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 100vh;
    left: 0;
  }
  .reduce__top {
    top: calc(100vh - 600px);
  }

  .thanks__wrapper {
    display: grid;
    place-items: center;
    text-align: center;
    gap: 3rem;
    padding: 2rem 10px 10rem;
    font-family: Access__Bold !important;
    h3 {
      font-family: Access__Bold !important;

      font-size: 4rem !important;
    }
    span {
      font-size: 1.5rem !important;
      max-width: 750px;
      width: 80%;
      color: #3d3d3d;
    }
  }
}
.flex__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    width: 600px;
    height: 750px;
  }
}
.grid {
  display: grid;

  align-items: center;
  gap: 2rem;
  grid-template-columns: repeat(2, 300px);

  img {
    height: 320px;
  }
}

@media (max-width: 1600px) {
  .flex__wrapper {
    img {
      width: 550px;
    }
  }
}

@media (max-width: 1500px) {
  .grid {
    grid-template-columns: repeat(2, 250px);
    img {
      width: 250px;
      height: 280px;
    }
  }
}

@media (max-width: 1480px) {
  .flex__wrapper {
    img {
      width: 540px;
    }
  }
  .grid {
    grid-template-columns: repeat(2, 230px);
    img {
      width: 230px;
      height: 250px;
    }
  }
}

@media (max-width: 1280px) {
  .flex__wrapper {
    img {
      width: 70%;
    }
  }
  .grid {
    grid-template-columns: repeat(1, 200px);
    img {
      width: 200px;
      height: 170px;
    }
  }
}

@media (max-width: 1109px) {
  .flex__wrapper {
    justify-content: center;
    img {
      width: 100%;
    }
  }

  .grid {
    margin-top: 5rem;
    grid-template-columns: repeat(4, 200px);
    justify-content: center;
    img {
      width: 200px;
      height: 200px;
    }
  }
}

@media (max-width: 800px) {
  .flex__wrapper {
    img {
      height: 600px;
    }
  }
  .grid {
    grid-template-columns: repeat(2, 250px);
    img {
      width: 250px;
      height: 230px;
    }
  }
}

@media (max-width: 650px) {
  .grid {
    grid-template-columns: repeat(2, 200px);
    img {
      width: 200px;
      height: 200px;
    }
  }
}

@media (max-width: 520px) {
  .grid {
    grid-template-columns: repeat(2, 180px);
    img {
      width: 180px;
      height: 180px;
    }
  }
}

@media (max-width: 447px) {
  .grid {
    grid-template-columns: repeat(2, 140px);
    img {
      width: 140px;
      height: 140px;
    }
  }
}

@media (max-width: 1280px) {
  .portfolio__container {
    .reduce__top {
      top: calc(100vh - 100px);
    }
  }
}
