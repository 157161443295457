.encompass__logo{
    width: 25%;
    margin: 10rem auto 20rem;
    img{
        width: 100%;
        object-fit: contain;
    }
}

.logo__making{
    width: 35%;
    margin: 20rem auto;
    img{
        width: 100%;
        object-fit: contain;
    }
}