.alignment__container2 {
  width: 70%;
  margin: 0px auto;
}

.portfolio__heading__container {
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0 0 0 0;
  z-index: 0;
  background-color: #000001;
  margin: 0px auto;
  color: #e6e6e6;
  padding-top: 250px;
}

.half__height {
  height: calc(100vh - 600px);
}

.portfolio__heading__wrapper {
  display: flex;
  width: 60%;
  height: 100%;
  margin: 0px auto;
}

.portfolio__heading {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin: 0px auto;

  h4 {
    font-size: 2.8rem;
    font-weight: bold;
    color: #e6e6e6;
  }

  h5 {
    padding-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: normal;
    color: #e6e6e6;
    opacity: 0.6;
    margin-top: 12px;
  }
}

.heading__center {
  justify-content: center;
}

.heading__bunch__center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading__bunch {
  width: 40%;
}

.mt-1 {
  margin-top: 0.3vw !important;
}
.-mt-1 {
  margin-top: -0.3vw !important;
}

.heading__wrapper {
  display: flex;
  align-items: center;
}

.portfolio__para {
  width: 86%;
  flex-direction: column;

  .secondaryHeading {
    font-weight: normal;
    color: #e6e6e6;
    opacity: 0.6;
    margin-top: 20px;
  }
  p {
    font-size: 1.5rem;
  }
}

@media (max-width: 1440px) {
  .portfolio__heading__container {
    width: 90%;
  }
}

@media (max-width: 1280px) {
  .portfolio__heading__container {
    padding-top: 160px;
  }

  .half__height {
    height: calc(100vh - 100px);
  }

  .portfolio__heading {
    width: 80%;
  }
  .heading__bunch {
    width: 45%;
  }

  .portfolio__para {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .portfolio__heading {
    width: 100%;
    justify-content: flex-start;
  }

  .portfolio__para {
    margin-top: 26px;
  }

  .heading__bunch {
    width: 100%;
  }
}

.alignment__container {
  width: 80%;
  margin: 0 auto;
}

.img__max__width {
  width: 100%;

  img {
    object-fit: contain;
    min-width: 100%;
    max-width: 100%;
  }
}

.big_heading {
  width: 100%;
  opacity: 0.2;

  // font-size: 1rem;
  h1 {
    font-size: 8rem;
  }
}

.full__img {
  width: 100%;

  img {
    width: 100%;
    object-fit: contain;
  }
}

.bottom__margin {
  margin-bottom: 5rem !important;
}

.top__margin {
  margin-top: 5rem !important;
}

.blade__margin {
  margin-top: 8rem !important;
}
.blade__padding {
  padding: 8rem 0 !important;
}
.text__center {
  text-align: center;
}

@import "./InformaticsPress";
@import "./IMA2020";
@import "./Encompass";
@import "./Sinless";
@import "./Sleek";
@import "./IMA2019";
@import "./ITAT";
@import "./VijayChatHouse";
@import "./Brebel";
@import "./BrebelBranding";
@import "./Spintly";
@import "./Senstra";
@import "./Evre";
@import "./EvreBranding";
@import "./Gavyam";
@import "./AuBank";
@import "./ShopX";
@import "./ShopXApp";
@import "./Fasal";
@import "./Sls";
@import "./Rudo";
@import "./DN99";
@import "./Varaha";
@import "./Sleek";
@import "./VerdemobilityApp";
@import "./VerdemobilityBranding";
@import "./CostItRight";
@import "./Swan";
