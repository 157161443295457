.tabs__container {
  display: flex;
  justify-content: flex-end;
  width: 85%;
  margin: 0 auto 3rem;
  .navLinks_a {
    height: 45px;
    cursor: pointer;
  }
}

.active__tab {
  color: rgb(0, 0, 0) !important;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff) !important;
}

.work__card__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1600px;
  width: 90%;
  column-gap: 2rem;
  row-gap: 8rem;
  margin: 0px auto 0;
  .card__work {
    flex: 1 1 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0.5rem;
    .img__wrapper {
      overflow: hidden;
      width: 100%;
      flex-basis: 1 1 350px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        max-height: 400px;
        transition: scale 300ms ease-in-out;
      }
    }
    h3 {
      font-size: 2.125rem;
    }
    h5 {
      font-size: 1.125rem;
    }

    // hover states

    .card__meta {
      display: grid;
      gap: 5px;
      .view__arrow {
        transition: opacity 300ms ease-in-out;
        opacity: 0;
        display: flex;
        justify-content: start;
        gap: 1rem;
        img {
          height: 25px;
          width: 30px;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    &:hover {
      .view__arrow {
        opacity: 1;
      }
      .img__wrapper img {
        scale: 1.1;
      }
    }
  }
}
