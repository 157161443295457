.MyStyle {
  background-color: #0426a1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.b {
  border: 2px solid red;
}
.MyImg {
  width: 25vw;
  height: fit-content;
  justify-content: center;
  margin: 7%;
}
.Logos_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  max-width: 200px;
  min-width: 10vw;
  gap: 5rem;
  padding-bottom: 5%;
}
.subpara_wrapper {
  width: 90%;
  padding: 10%;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.textStyle {
  word-spacing: 1;
  width: 30%;
}
.textStyleP {
  margin: auto auto;
  margin-left: 10%;
  width: 52%;
}
.background_container {
  background-color: #eeeeee;
  display: flex;
  flex-wrap: wrap;
}
.style_container {
  background-color: #ffffff;
  margin-left: 10%;
  margin-top: -5%;
  width: 30%;
  max-width: auto;
  padding: 2rem;
  margin-bottom: 5%;
  z-index: 1;
}
.para_container {
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 10%;
}
.element_container {
  background-color: #0426a1;
  width: 140%;
  color: #ffffff;
  padding: 1rem;
}
.our_container {
  margin-top: 5%;
  margin-left: 1%;
  margin-bottom: 10%;
}
.backgroundblack {
  background-color: #171515;
  margin-left: 15%;
  margin-top: 10%;
  width: 30%;
  padding: 4rem;
  height: 78vh;
  margin-bottom: 15%;
}
.wordh3 {
  color: #ffffff;
  size: 4rem;
  margin-top: 10%;
  margin-left: 5%;
}
.backgroundblue {
  background-color: #0426a1;
  width: 30%;
  padding: 4rem;
  margin-top: 20%;
  max-width: auto;
  margin-left: 10%;
  height: 80vh;
  margin-bottom: 10%;
}
.wordp {
  color: #ffffff;
  size: 2rem;
  margin-top: 50%;
  font-style: italic;
  font-family: Playfair__Bolditalic;
}
.black_container {
  background-color: #212121;
}
.font {
  color: #ffffff;
}
.flow {
  margin-top: -5%;
  width: 80%;
  margin-bottom: 8%;
}
.backdown_container {
  background-color: #eeeeee;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
}
.textcolor {
  color: #0426a1;
  padding: 1rem;
}
.textp {
  font-size: 18px;
}
.all {
  flex-basis: 50%;
  margin-left: 10%;
}
.card {
  width: 385px;
  height: 500px;
  border-radius: 10px;
  padding: 15px 25px;
  box-sizing: border-box;
  margin: 10px 15px;
  display: flex;
}
.art-container {
  margin-top: 15%;
  margin-left: 15%;
  justify-content: center;
  display: flex;
}
.first_grid {
  width: 100%;
  margin-top: 4%;
}
.color_sls {
  color: #0426a1;
  size: 54px;
  font-style: bold;
  font-size: 2.7rem;
}
b {
  font-weight: bold;
}
.text-para {
  padding-bottom: 1rem;
  line-height: 3rem;
}
.para-art {
  font-size: 22px;
  line-height: 2.3rem;
}
.second_grid {
  margin-right: 10%;
  margin-bottom: 20%;
}
.rectangle_grid {
  width: 30vw;
}
.gap {
  padding-left: 3rem;
}
.Man_banner {
  filter: grayscale(100%);
  z-index: -1;
  width: 100%;
}
.gap_second {
  padding-left: 7rem;
}
.collage {
  width: auto;
  background-size: 100%;
}
.gridbackground {
  background-image: url(../assets/slsAssets/gridsystem.png);
  background-size: full-width;
  height: 100vh;
  display: flex;
}
.gridback {
  margin-left: -15%;
  width: 100%;
  padding-top: 12%;
}
.grid_text {
  padding: 5%;
  margin-right: 10%;
  margin-top: 3%;
  color: #ffffff;
}
.background_blue {
  background-color: #0426a1;
  margin-top: 12%;
}
.img_container {
  width: 100%;
  align-items: center;
  margin-top: -12%;
}
.img_containersecond {
  width: 90%;
  align-items: center;
  margin-top: -3%;
  padding-left: 5%;
  padding-right: 5%;
  margin-left: 5%;
}
.solar_container {
  opacity: 0.5;
}
.solarwind_container {
  background-color: #eeeeee;
}
.img_solar {
  width: 72%;
  margin-left: 14%;
  margin-top: -26%;
}
.testing_container {
  opacity: 100%;
}

.tesingCalibration {
  width: 70%;
  align-items: center;
  margin-top: -50%;
  margin-left: 15%;
}
.multiple_container {
  margin-top: 10%;
  font-size: 1.2rem;
  width: 30%;
  margin-left: 12%;
}
.grey_container {
  background-color: #eeeeee;
  margin-top: 10%;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  margin-bottom: auto;
  overflow: hidden;
}
.services_container1 {
  width: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: -10%;
}
.services_container2 {
  width: 25%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
}
.services_container3 {
  width: 40%;
  margin-top: 5%;
  margin-bottom: 10%;
}
.services_container4 {
  width: 25%;
  margin-top: 5%;
  margin-bottom: 5%;
}
@media only screen and (max-width: 1100px) {
  .MyStyle {
    height: fit-content;
  }
  .services_container1 {
    width: 60%;
    margin-left: 16%;
  }

  .services_container2 {
    width: 60%;
    margin-left: 16%;
  }
  .services_container3 {
    width: 80%;
    margin: 5% auto;
    margin-left: 5%;
  }
  .services_container4 {
    width: 60%;
    margin-left: 15%;
  }
  .MyImg {
    width: 300px;
  }

  .Logos_wrapper {
    .image {
      width: 180px;
    }
  }
  .art-container {
    flex-direction: column;
    padding: auto;
  }
  .Logos_wrapper {
    flex-wrap: wrap;
    width: 40vw;
  }
  .para_container {
    margin-left: 10%;
    padding-right: 20px;
  }
  .subpara_wrapper {
    width: 100%;
    flex-direction: column;
    margin: auto auto;
  }
  .rectangle_grid {
    width: 50vw;
    padding-top: 3rem;
  }
  .textStyle {
    margin: 2%;
    width: 70%;
  }
  .textStyleP {
    margin-bottom: 5%;
    margin-left: auto;
    width: auto;
  }
  .style_container {
    width: 70%;
    z-index: 999999 !important;
  }
  .background_container {
    flex-direction: column;
    width: 100%;
  }
  .element_container {
    width: auto;
  }
  .grey_container {
    margin: auto auto;
    flex-direction: column;
  }
  .backgroundblack {
    width: 85%;
    height: 35vh;
  }
  .wordh3 {
    font-size: 1.4rem;
    width: 100%;
    line-height: 2;
  }
  .backgroundblue {
    width: 85%;
    height: 35vh;
    margin-top: auto;
    margin-left: 15%;
    padding: 2rem;
  }
  .flow {
    width: 100vw;
  }
  .wordp {
    font-size: 1.4rem;
    width: 100%;
    margin-top: 3%;
    padding: auto;
  }
  .text-para {
    margin: 2%;
  }
  .para-art {
    width: auto;
    margin-right: 5%;
    font-size: 1.4rem;
  }
  .grid {
    flex-direction: row;
    width: 70%;
  }
  .gridbackground {
    flex-direction: column;
    height: auto;
  }
  .grid_text {
    margin-bottom: auto;
    margin-left: 5%;
  }
  .multiple_para {
    width: 170%;
    margin: auto;
  }
  .Man_banner {
    height: 10vh;
  }
  .rectangle_grid {
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .backgroundblack {
    width: 50%;
  }
  .backgroundblue {
    width: 50%;
  }
  .wordp {
    margin-top: 30%;
  }
  .MyImg {
    width: 200px;
  }
  .Logos_wrapper {
    .image {
      width: 140px;
    }
  }
}

