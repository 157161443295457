.page__heading__container {
  width: 80%;
  margin: 0% auto;
  margin-top: 10%;
}

.page__heading {
  display: flex;
  justify-content: space-between;
  h2 {
    padding: 1rem;
  }
}

.sub__heading {
  padding: 1rem;
  width: 50%;
  p {
    width: 80%;
    line-height: 1.4;
  }
}


@media (max-width: 992px)
{
  .page__heading__container {
    h2 {
      font-size: 5.5rem;
    }
  }
}

@media (max-width: 830px)
{
  .page__heading__container {
    h2 {
      font-size: 5rem;
    }
  }
}

@media (max-width: 625px)
{
  .page__heading__container {
    h2 {
      font-size: 4.5rem;
    }
  }
}

@media (max-width: 500px)
{
 .page__heading__container {
  h2 {
    font-size: 4rem;
  }
 }
}

@media (max-width: 345px) {
  .page__heading__container {
    h2 {
      font-size: 3.3rem;
    }
  }
}