.footer__container {
  background-color: #000001;
  color: white;
  padding-top: hr {
    width: 250px;
    margin: 0 auto;
    height: 3px;
  }
  h3 {
    padding-top: 2rem;
    font-size: 2.125rem;
  }
  .link__wrapper {
    display: grid;
    place-content: center;
    padding: 5px 20px 6rem;
    .footer__link {
      position: relative;
      padding: 25px 0px 15px 0px;
      transition: all 300ms ease;
      display: flex;
      align-items: center;
      font-family: Access__Bold;
      margin-right: auto;
      font-size: 1.25rem;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        margin: auto;
        height: 0.125rem;
        left: 0;
        bottom: 0;
        right: 0;
        transform: scale(1);
        background: black;
        transform-origin: left;
        transition: transform 0.3s ease;
      }

      &:hover::after {
        transform: scale(0, 1);
        transform-origin: right;
      }
    }
  }
  .socials__wrapper {
    border-top: 1px solid #d8d8d8;
    padding: 30px 10px;
    .wrapper__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      flex-wrap: wrap;
      row-gap: 20px;
      width: 85%;
      margin: 0 auto;
    }
    span {
      font-family: Access__Regular;
      font-weight: bold;
      font-size: 1.125rem;
    }
  }
  .footer__social {
    img {
      height: 40px;
      width: 40px;
      margin-right: 1rem;
    }
  }
}

.sweep_to_right {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.sweep_to_right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.sweep_to_right:hover,
.sweep_to_right:focus,
.sweep_to_right:active {
  color: #000005;
  stroke: #000005;
}
.sweep_to_right:hover:before,
.sweep_to_right:focus:before,
.sweep_to_right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
