.connect__headings {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85%;
  height: 50vh;
  margin: 5% auto;

  h1 {
    font-size: 5.2rem;
  }

  .headings {
    width: 45%;
  }

  .contact__container {
    width: 60%;
  }
}

.contact__container {
  display: flex;
  justify-content: space-around;
}

.business__enquiries {
  h2 {
    font-size: 2.5rem;
  }
  .phone__numbers {
    h5 {
      padding: 12px 10px 8px;
    }
  }
  .phone__numbers a:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
}

.contact__info {
  h5 {
    font-size: 1.8rem;
    padding: 0rem 1rem;
  }
}

.mail {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  img {
    width: 40px;
  }

  p {
    font-size: 2rem;
    padding-left: 2rem;
  }
}

.phone {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  img {
    width: 40px;
  }

  p {
    font-size: 2rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
  }
}

.contact__footer {
  width: 80%;
  height: 40vh;
  margin: 0 auto;
  text-align: center;
  display: grid;
  place-content: center;

  h5 {
    width: 80%;
    margin: 0 auto 3rem;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
  }

  h6 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 2rem;
  }
  a:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
}

@media (max-width: 1080px) {
  .connect__headings {
    height: fit-content;

    h1 {
      font-size: 4rem !important;
    }

    .headings {
      width: 50%;
    }

    .contact__container {
      flex-wrap: wrap;
      width: 50%;
    }
  }
}

//  new page desing
.bladeA__master {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-around;
  .info__wrapper,
  .form__wrapper {
    flex: 1 1 400px;
  }
}
